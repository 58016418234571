<template>
  <div class="publications" v-if="currentRoute">
    <stage :stageLabel="$i18n.locale === 'de' ? 'Publikationen' : 'Publications'" :big="true" :title="currentRoute.title.rendered"/>
    <introduction
        fontWeight="bold"
        :subline="currentRoute.title.rendered"
        :maintext="currentRoute.acf.description"
    />    
    <div v-if="pagedPublications.length > 0" ref="publicationslist" class="publications__content">
        <projects :items="pagedPublications"/>
        <pagination @change-current="setCurrentPage" :currentPage="currentPage" :totalPages="Number(getPublicationsListProps.totalPages)"/>
    </div>
    <div v-else ref="publicationslist" class="publications__content publications__content--empty">
        <h2>{{$i18n.locale === 'de' ? 'Aktuell sind keine Publikationen verfügbar.' : 'No publications are currently available.'}}</h2>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Stage from '@/components/stage.vue'
import Introduction from '@/components/introduction.vue'
import Projects from '@/components/jobs-blocks.vue'
import {mapActions, mapGetters} from 'vuex'
import Pagination from '../components/pagination.vue'

export default {
  name: 'Publikationen',
  components: {
    Stage,
    Introduction,
    Projects,
    Pagination
  },
  data() {
    return {
        currentPage: 1,
    }
  },
  computed: {
      ...mapGetters([
          'getPublications',
          'getPublicationsListProps',
          'getFetched',
      ]),
      currentRoute(){
          const getFetched =  this.getFetched[this.$route.name];
          return getFetched;
      },
      pagedPublications() {
        return this.getPublications.slice((this.currentPage - 1) * 10, this.currentPage * 10)
      }
  },
  created(){
      if(!this.currentRoute) {
          this.fetchCurrentPage(this.$route.name);
      }
      if(this.getPublications.length === 0) {
          this.fetchPublications(this.currentPage)
      }
  },
  watch: {
      currentPage( cur, old) {
          if(Number(this.getPublicationsListProps.totalItems) > old * 10 && this.getPublications.length !== Number(this.getPublicationsListProps.totalItems)) {
              this.fetchPublications(cur)
          }
          this.scrollTo(this.$refs.publicationslist);
      }
  },
  methods: {
      ...mapActions([
          'fetchPublications',
          'fetchCurrentPage',
      ]),
      setCurrentPage(val) {
          if(val <= this.getPublicationsListProps.totalPages && val > 0) {
              this.currentPage = val
          }
      },
      scrollTo(ref) {
      window.scrollTo({
        top: ref.offsetTop - 150,
        left: 0,
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.publications {
  margin-top: 86px;
  padding: 0 .8rem;
  &__content {
    max-width: 1140px;
    margin: auto;
    padding:  0 .8rem;
    &--empty {
      text-align: center;
      margin-bottom: 86px;
    }
  }
}
</style>
