<template>
  <div class="projects" v-if="currentRoute">
    <stage :stageLabel="$i18n.locale === 'de' ? 'Projekte' : 'Projects'" :big="true" :title="currentRoute.title.rendered"/>
    <introduction
        fontWeight="bold"
        :subline="currentRoute.title.rendered"
        :maintext="currentRoute.acf.description"
    />    
    <div v-if="pagedProjects.length > 0" ref="projectlist" class="projects__content">
        <projects :items="pagedProjects"/>
        <pagination @change-current="setCurrentPage" :currentPage="currentPage" :totalPages="Number(getProjectListProps.totalPages)"/>
    </div>
    <div v-else ref="projectlist" class="projects__content projects__content--empty">
        <h2>{{$i18n.locale === 'de' ? 'Aktuell sind keine Projekte verfügbar.' : 'No projects are currently available.'}}</h2>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Stage from '@/components/stage.vue'
import Introduction from '@/components/introduction.vue'
import Projects from '@/components/jobs-blocks.vue'
import {mapActions, mapGetters} from 'vuex'
import Pagination from '../components/pagination.vue'

export default {
  name: 'Projekte',
  components: {
    Stage,
    Introduction,
    Projects,
    Pagination
  },
  data() {
    return {
        currentPage: 1,
    }
  },
  computed: {
      ...mapGetters([
          'getProjects',
          'getProjectListProps',
          'getFetched',
      ]),
      currentRoute(){
          const getFetched =  this.getFetched[this.$route.name];
          return getFetched;
      },
      pagedProjects() {
        return this.getProjects.slice((this.currentPage - 1) * 10, this.currentPage * 10)
      }
  },
  created(){
      if(!this.currentRoute) {
          this.fetchCurrentPage(this.$route.name);
      }
      if(this.getProjects.length === 0) {
          this.fetchProjects(this.currentPage)
      }
  },
  watch: {
      currentPage( cur, old) {
          if(Number(this.getProjectListProps.totalItems) > old * 10 && this.getProjects.length !== Number(this.getProjectListProps.totalItems)) {
              this.fetchProjects(cur)
          }
          this.scrollTo(this.$refs.projectlist);
      }
  },
  methods: {
      ...mapActions([
          'fetchProjects',
          'fetchCurrentPage',
      ]),
      setCurrentPage(val) {
          if(val <= this.getProjectListProps.totalPages && val > 0) {
              this.currentPage = val
          }
      },
      scrollTo(ref) {
      window.scrollTo({
        top: ref.offsetTop - 150,
        left: 0,
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.projects {
  margin-top: 86px;
  padding: 0 .8rem;
  &__content {
    max-width: 1140px;
    margin: auto;
    padding:  0 .8rem;
    &--empty {
      text-align: center;
      margin-bottom: 86px;
    }
  }
  &__sectionhead {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-family:"neue-plak";
    font-weight: 500;
    color: #182952;
    margin: 0 0 65px 0;
    &--left {
        justify-content: flex-start;
    }
  }
}
</style>
